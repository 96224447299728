/**
 * feelgood theme custom JS main file
 */
$(document).ready(function () {
  // When the '#video' tab is clicked
  $("#video").on("click", function () {
    $(".outline").hide(); // Hide the '.outline' content
    $(".video_all").show(); // Show the '.video_all' content
    $(this).addClass("active"); // Optional: Add 'active' class to the clicked tab
    $("#outline").removeClass("active"); // Optional: Remove 'active' class from the other tab
  });

  // When the '#outline' tab is clicked
  $("#outline").on("click", function () {
    $(".video_all").hide(); // Hide the '.video_all' content
    $(".outline").show(); // Show the '.outline' content
    $(this).addClass("active"); // Optional: Add 'active' class to the clicked tab
    $("#video").removeClass("active"); // Optional: Remove 'active' class from the other tab
  });
});

jQuery(document).ready(function () {
  $(".modal-trigger").on("click", function () {
    var modalId = $(this).data("target");
    var videoUrl = $(this).data("video");
    // Construct video HTML
    var videoHtml =
      '<video width="100%" controls><source src="' +
      videoUrl +
      '" type="video/mp4"></video>';

    // Display the video in the modal
    $("#" + modalId + " .video-container").html(videoHtml);

    // Show the modal
    $("#" + modalId).show();
  });
  $(".video-wrapper svg").on("click", function () {
    var $svg = $(this);
    var video = $svg.closest(".solution-modal").find("video")[0];

    if (video) {
      if (video.paused) {
        video.play();
        $svg.hide(); // Hide the SVG when video plays
      } else {
        video.pause();
        $svg.show(); // Optional: show the SVG when video pauses
      }
    }
  });
  $(document).on("play", "video", function () {
    // Assuming the SVG is a sibling or a child of the video's parent container
    $(this).closest(".video-container").find("svg").hide();
  });

  $(".close-solution-modal").on("click", function () {
    $(this).closest(".solution-modal").hide();
  });

  $("#fullwidth_play").on("click", function (e) {
    e.preventDefault();

    var videoFile = $("#videoplaceholder").data("file");
    var mimeType = $("#videoplaceholder").data("mime");

    var videoHtml =
      '<video width="100%" controls autoplay>' +
      '<source src="' +
      videoFile +
      '" type="' +
      mimeType +
      '">' +
      "Your browser does not support the video tag." +
      "</video>";

    $("#videoplaceholder").html(videoHtml);
    $(".fullwidth-video").find(".content").slideUp("fast");
  });

  /**
   * Mobile Search
   */

  $("#mobile_search_trigger").click(function () {
    // Toggle slide for the search bar
    $("#mobileSearch").slideToggle("fast");

    // Slide up the notice bar if it exists
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
    }
  });


  /**
   * Mobile Menu Trigger & Function
   */
  const burgerMenubtn = $("#burger");
  const mobileNav = $(".mobile-slide-menu");
  const header = $('#masthead');

  burgerMenubtn.click(function () {
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
      $("#noticeBar").toggleClass('nope_go_away');
    }
    $("#mobile_search_trigger").slideToggle("fast");
    mobileNav.toggleClass("mobile_nav_active");
    header.toggleClass('fix_me_up');
  });

  //homeheroslider
  if ($(".blog-feed,.past_events").length) {
    //Create slider on load
    blogSlider();
  }

  $('.description_toggle').click(function () {
    $('.description').fadeToggle(); // Use fadeToggle for a smooth transition
  });

  // Open the menu
  $('#mobile_browse').click(function () {
    // Toggle 'active_menu' class to indicate menu's state
    $('#fullscreenMenu').toggleClass('active_menu');

    // Determine the top position based on whether the menu has 'active_menu' class
    var topPosition = $('#fullscreenMenu').hasClass('active_menu') ? '0' : '-100%';

    // Animate the menu to slide down or up based on topPosition
    $('#fullscreenMenu').animate({
      top: topPosition
    }, 500);

    // Toggle 'fix_me_up' class on the header and noticeBar visibility
    header.toggleClass('fix_me_up');
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
      $("#noticeBar").toggleClass('nope_go_away');
    }
  });

  // Close the menu
  $('#closeMenu').click(function () {
    $('#fullscreenMenu').animate({
      top: '-100%'
    }, 500); // Slide up
  });

  //copy to clip
  $("#copylink").click(function (e) {
    e.preventDefault;
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert("Post URL copied to clipboard.");
  });

  //homeheroslider
  if ($(".home-hero ").length) {
    //Create slider on load
    homeSlider();

    if (screen.width < 992) {
      searchesSlider();
    }
  }

  if ($(".facilitator_colour_slider ").length) {
    //Create slider on load
    facilitatorColSlider();
  }

  if ($(".facilitator-slider ").length) {
    //Create slider on load
    facilitatorSlider();
  }

  //reviews
  if ($(".workshop-carousel ").length) {
    //Create slider on load
    initSlickSliderSolutionsCarousel();

    $("a[data-term]").click(function (e) {
      e.preventDefault();
      $('li').removeClass('active');

      var termId = $(this).data("term");
      $(this).parent().addClass('active');

      $.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "filter_workshops",
          term_id: termId,
        },
        success: function (response) {
          // Destroy the current Slick slider
          if ($(".workshop-carousel").hasClass("slick-initialized")) {
            $(".workshop-carousel").slick("unslick");
          }

          $(".workshop-carousel").html(response); // Update the carousel with the new content

          initSlickSliderSolutionsCarousel();
        },
      });
    });
  }

  if ($(".categories-slider").length) {
    heroCatSlider();
    // On clicking the left arrow, set RTL to true (reverse scroll direction)
    $(".left").on('click', function () {
      clearInterval($(".categories-slider").data('autoScrollInterval')); // Clear existing interval
      $(".categories-slider").slick('slickSetOption', 'rtl', false, true);
      $(".categories-slider").slick('slickSetOption', 'speed', 8000, true);

    });

    // On clicking the right arrow, set RTL to false (normal scroll direction)
    $(".right").on('click', function () {
      clearInterval($(".categories-slider").data('autoScrollInterval')); // Clear existing interval
      $(".categories-slider").slick('slickSetOption', 'rtl', true, true);
      $(".categories-slider").slick('slickSetOption', 'speed', 8000, true);

    });

  }

  //accordion
  if ($(".faqs").length) {
    if ($(".accordion").length) {
      var allPanels = $(".accordion dd").hide();

      $(".accordion dt > a").click(function () {
        if ($(this).hasClass("active")) {
          $(this).parent().next().slideUp();
          $(this).removeClass("active");
        } else {
          allPanels.slideUp();
          $(".accordion dt > a").removeClass("active");
          $(this).addClass("active");
          $(this).parent().next().slideDown();
          return false;
        }
      });
    }
  }
});

/*header*/
jQuery(document).ready(function ($) {
  let header = $(".scrolled-nav");
  let lastScrollTop = 0;
  let didScroll;
  let docHeight = $(document).height();
  let windowHeight = $(window).height();
  let scrollTrigger = docHeight * 0.08; // 10% of the page height for mobile

  $(window).scroll(function () {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    let scrollTop = $(this).scrollTop();

    // Check if the window width is less than or equal to 768px (mobile device)
    if ($(window).width() <= 768) {
      // Mobile device functionality
      if (scrollTop > scrollTrigger) {
        header.addClass("fixed-top");
      } else {
        // Remove fixed-top class when scrolling back into the top 10% of the page
        header.removeClass("fixed-top");
      }
    } else {
      // Original functionality for larger devices
      if (scrollTop > 500 && scrollTop <= lastScrollTop) {
        header.addClass("fixed-top");
      }
      if (scrollTop < 500) {
        header.removeClass("fixed-top");
      }
      if (
        (header.hasClass("fixed-top") && scrollTop > lastScrollTop) ||
        scrollTop < 400
      ) {
        header.removeClass("fixed-top");
      }
    }

    lastScrollTop = scrollTop;
  }
});

if ($("#video-placeholder").length) {
  /**
   * Video Load on Scroll
   */
  document.addEventListener("DOMContentLoaded", function () {
    var observer = new IntersectionObserver(
      function (entries) {
        // Loop over the entries
        entries.forEach(function (entry) {
          // If the element is in view
          if (entry.isIntersecting) {
            // Get the placeholder element
            var videoPlaceholder = document.getElementById("video-placeholder");
            // Create the video element
            var video = document.createElement("video");
            video.autoplay = true;
            video.muted = true;
            video.loop = true;

            // Add the source to the video
            var source = document.createElement("source");
            source.src = videoData.url;
            source.type = videoData.type;
            video.appendChild(source);

            // Replace the placeholder with the video
            videoPlaceholder.innerHTML = "";
            videoPlaceholder.appendChild(video);

            // Unobserve the placeholder so it only loads once
            observer.unobserve(videoPlaceholder);
          }
        });
      }, {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Target the video placeholder
    var videoPlaceholder = document.getElementById("video-placeholder");
    observer.observe(videoPlaceholder);
  });
}

function initSlickSliderSolutionsCarousel() {
  $(".workshop-carousel").slick({
    // Your slick options go here
    dots: false,
    arrows: true,
    prevArrow: $(".left"),
    nextArrow: $(".right"),
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
        breakpoint: 1200, // For screens less than or equal to 1200px
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // For screens less than or equal to 992px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function homeSlider() {
  $(".logo-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3400,
    autoplay: true,
    pauseOnHover: false, // Added this line to prevent pausing on hover
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200, // For screens less than or equal to 1200px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // For screens less than or equal to 992px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function searchesSlider() {
  $(".home-hero .searches").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    center: false,
    speed: 300,
    slidesToShow: 1.5,
    slidesToScroll: 1,
  });
}

if ($(".workshop-facilitators").length) {
  jQuery(document).ready(function ($) {
    $(".workshop-facilitators").on("click", ".facilitators a", function (e) {
      e.preventDefault();
      var facilitatorID = $(this).data("id");
      var workshopID = $(this).closest(".facilitators").data("workshop-id");

      $.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "get_facilitator_data",
          facilitator_id: facilitatorID,
          workshop_id: workshopID,
        },
        success: function (response) {
          var $img = $(".background img");
          $img.attr("src", response.data.main_image_url);
          $img.attr("srcset", response.data.main_image_srcset);

          $(".biography h3").text(response.data.name);
          $(".biography big").text(response.data.qualifications_cs);
          $(".biography p").text(response.data.bio);

          $(".facilitators").html(response.data.facilitators_html);

        },
        error: function (errorThrown) {
          console.log(errorThrown);
        },
      });
    });
  });
}

function blogSlider() {
  $(".posts-slider,.past_events").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function facilitatorSlider() {
  $(".facilitator-slider").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 6700,
    autoplay: true,
    pauseOnHover: false, // Added this line to prevent pausing on hover
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: "linear", // This will create a smooth constant scrolling without acceleration
    slidesToShow: 6.5,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 4.8, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2.2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.8, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

/**
 * Facilitator Archive Region Filter
 */
jQuery(document).ready(function ($) {
  $(".facil_filters a").on("click", function (e) {
    e.preventDefault();

    var regionId = $(this).data("region");
    $(".facil_filters a").removeClass("active");
    $(this).addClass("active");

    $.ajax({
      url: "/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "get_facilitators_by_region", // WP AJAX action hook
        region_id: regionId, // The selected region ID
      },
      success: function (response) {
        $(".facilitators-by-region").html(response); // Update the facilitators list
      },
    });
  });
});

function heroCatSlider() {
  $(".categories-slider").slick({
    dots: false,
    autoplay: true,
    speed: 8000,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: "linear", // Adjust the speed of auto-scrolling
    arrows: true,
    prevArrow: $(".left"),
    nextArrow: $(".right"),
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    responsive: [{
        breakpoint: 1024, // Screen width up to 1024px
        settings: {
          slidesToShow: 3, // Show 3 slides for medium-sized screens
        },
      },
      {
        breakpoint: 768, // Screen width up to 768px
        settings: {
          slidesToShow: 2, // Show 2 slides for small-sized screens
        },
      },
      {
        breakpoint: 480, // Screen width up to 480px
        settings: {
          slidesToShow: 1, // Show 1 slide for extra small screens
        },
      },
    ],
  });
}

jQuery(document).ready(function ($) {

  // Use .one() to ensure the hover event is handled only once
  $('a').one('mouseenter', function () {
    // Check if the script is already loaded to avoid loading it multiple times
    if (!window.customScriptLoaded) {
      var scriptUrl = '/wp-content/themes/feelgood/assets/dist/tween.js'; // Change this to the actual path of your JS file

      $.getScript(scriptUrl, function () {
        // Script is loaded and executed, you can run any callback here if needed
        console.log('Script loaded successfully.');

        // Set a flag to indicate the script is loaded
        window.customScriptLoaded = true;

        // Initialize magnetic buttons here
        initializeMagneticButtons();
      });
    }
  });

  function initializeMagneticButtons() {
    /*
     * Magnetic Buttons
     */
    var magnets = $('.magnetic'); // Using jQuery for consistency
    var strength = 50;

    magnets.each(function () {
      var magnet = $(this); // Current element in the loop

      magnet.on("mousemove", function (event) {
        moveMagnet(event, magnet);
      });

      magnet.on("mouseout", function (event) {
        TweenMax.to(this, 1, {
          x: 0,
          y: 0,
          ease: Power4.easeOut
        });
      });
    });

    function moveMagnet(event, magnetButton) {
      var bounding = magnetButton[0].getBoundingClientRect();

      TweenMax.to(magnetButton, 1, {
        x: ((event.clientX - bounding.left) / bounding.width - 0.5) * strength,
        y: ((event.clientY - bounding.top) / bounding.height - 0.5) * strength,
        ease: Power4.easeOut,
      });
    }
  }
});


$(document).ready(function () {
  $('.tab').click(function () {
    // Remove 'active' class from all tabs
    $('.tab').removeClass('active');

    // Add 'active' class to the clicked tab
    $(this).addClass('active');

    // Scroll to the corresponding content section
    var targetId = $(this).data('anchor');
    if (targetId) {
      var targetElement = $(targetId);
      if (targetElement.length) {
        $('html, body').animate({
          scrollTop: targetElement.offset().top
        }, 300);
      }
    }

    // Ensure the "NOW" tab maintains its unique style
    if ($(this).hasClass('now-tab')) {
      $('.now-tab').removeClass('active'); // Prevents overriding the gradient
    }
  });
});
$(document).ready(function () {
  function onScroll() {
    var scrollPos = $(document).scrollTop();
    $('.tab').each(function () {
      var currTab = $(this);
      var targetId = currTab.data("anchor");
      var targetElement = $(targetId);
      if (targetElement.length) {
        var targetTop = targetElement.offset().top - (targetElement.outerHeight() / 2);
        var targetHeight = targetElement.outerHeight();
        if (scrollPos >= targetTop && scrollPos < (targetTop + targetHeight)) {
          $('.tab').removeClass("active");
          currTab.addClass("active");
          // Ensure the "NOW" tab maintains its unique style
          if (currTab.hasClass('now-tab')) {
            $('.now-tab').removeClass('active');
          }
        }
      }
    });
  }

  if ($('.tab').length) {
    $(window).on("scroll", onScroll);
  }
});

function facilitatorColSlider() {
  // Base configuration for both sliders
  var baseConfig = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    draggable: false,
    swipe: false,
    touchMove: false,
    pauseOnHover: false, // Added this line to prevent pausing on hover
  };

  // Specific settings for the first slider
  var sliderOneConfig = $.extend({}, baseConfig, {
    slidesToShow: 3.7,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.9, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.7, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Specific settings for the second slider
  var sliderTwoConfig = $.extend({}, baseConfig, {
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.8, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.8, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Initialize sliders with respective configurations
  $(".facilitator_colour_slider").slick(sliderOneConfig);
  $(".facilitator_colour_slider_two").slick(sliderTwoConfig);
}

/**
 * Menu
 */
// Event listener for mouseenter on elements with the class '.solutions-link'
$(document).ready(function () {
  var timeout; // Declare timeout variable outside to make it accessible in both handlers

  $(document).on("mouseenter", ".solutions-link, #solutionsMenu", function () {
    clearTimeout(timeout); // Clear any running timeout to cancel hiding the menu
    $("#solutionsMenu").addClass("show");
  });

  $(document).on("mouseleave", ".solutions-link, #solutionsMenu", function () {
    // Start a new timeout only if it is not already set
    timeout = setTimeout(function () {
      $("#solutionsMenu").removeClass("show");
    }, 350); // Timeout after 300 milliseconds (0.3 seconds)
  });
});
$(document).ready(function () {
  var timeout; // Declare timeout variable outside to make it accessible in both handlers

  $(document).on("mouseenter", ".resources-link, #resourcesMenu", function () {
    clearTimeout(timeout); // Clear any running timeout to cancel hiding the menu
    $("#resourcesMenu").addClass("show");
  });

  $(document).on("mouseleave", ".resources-link, #resourcesMenu", function () {
    // Start a new timeout only if it is not already set
    timeout = setTimeout(function () {
      $("#resourcesMenu").removeClass("show");
    }, 350); // Timeout after 300 milliseconds (0.3 seconds)
  });
});
$(document).ready(function () {
  var timeout; // Declare timeout variable outside to make it accessible in both handlers
  if ($('.case-study-link').length) {

    $(document).on("mouseenter", ".case-study-link, #casestudyMenu", function () {
      clearTimeout(timeout); // Clear any running timeout to cancel hiding the menu
      $("#casestudyMenu").addClass("show");
      $(".case_study_slider").slick({
        dots: false,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplay: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        responsive: [{
          breakpoint: 999, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 1, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        }, ],
      });
    });

    $(document).on("mouseleave", ".case-study-link, #casestudyMenu", function () {
      // Start a new timeout only if it is not already set
      timeout = setTimeout(function () {
        $("#casestudyMenu").removeClass("show");
      }, 350); // Timeout after 300 milliseconds (0.3 seconds)
    });
  }
});

$(document).ready(function () {
  if ($(".reviews-slider-solutions").length) {
    $(".reviews-slider-solutions").slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 4600,
      autoplay: true,
      autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
      cssEase: "linear", // This will create a smooth constant scrolling without acceleration
      slidesToShow: 3.6,
      slidesToScroll: 1,
      responsive: [{
          breakpoint: 1500, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 3.2, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1300, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 3, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1000, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 2, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768, // For screens less than or equal to 668px
          settings: {
            slidesToShow: 2, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 540, // For screens less than or equal to 668px
          settings: {
            slidesToShow: 1.2, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
});

/**
 * Search Menu
 */
$(document).ready(function () {
  var searchInput = $("header #s"); // Replace with your actual search input ID
  var searchMenu = $("#searchMenu");

  // Add 'show' class when the input is focused
  searchInput.focus(function () {
    searchMenu.addClass("show");
  });

  // Remove 'show' class when the input loses focus, with a slight delay
  searchInput.blur(function () {
    setTimeout(function () {
      searchMenu.removeClass("show");
    }, 200); // Adjust the delay time as needed
  });

  // Remove 'show' class when typing, re-add if input is empty
  searchInput.on("input", function () {
    if ($(this).val().length < 1) {
      searchMenu.addClass("show");
    } else {
      searchMenu.removeClass("show");
    }
  });

  // Prevent blur when clicking inside the searchMenu
  searchMenu.on("mousedown", function (e) {
    e.preventDefault();
  });
});

/**
 * Popup
 */
$(document).ready(function () {
  // Open modal
  $("#watchVideo").on("click", function (e) {
    e.preventDefault();
    var videoPath = $("#videoContainer").data("video");
    // Constructing video player
    var videoPlayer =
      '<video width="560" height="315" controls>' +
      '<source src="' +
      videoPath +
      '" type="video/mp4">' +
      "Your browser does not support the video tag." +
      "</video>";

    $("#videoContainer").html(videoPlayer);
    $("#videoModal").show();
  });

  // Close modal when clicking the close button
  $(".close-video-modal").on("click", function () {
    $("#videoModal").hide();
    $("#videoContainer").html(""); // Remove the video iframe
  });
  // Close modal when clicking outside of it
  $(window).on("click", function (e) {
    var modal = $("#videoModal");
    if (e.target.id === "videoModal") {
      modal.hide();
      $("#videoContainer").html(""); // Remove the video iframe
    }
  });
});

$(document).ready(function () {
  if ($(".reels").length) {
    $(".reels").slick({
      dots: false,
      arrows: false,
      infinite: false,
      autoplay: false,
      //autoplaySpeed: 2200,
      slidesToShow: 3.2,
      slidesToScroll: 1,
      responsive: [{
          breakpoint: 1500, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 3.2, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1300, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 3, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1000, // For screens less than or equal to 768px
          settings: {
            slidesToShow: 2, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768, // For screens less than or equal to 668px
          settings: {
            slidesToShow: 1.5, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 540, // For screens less than or equal to 668px
          settings: {
            slidesToShow: 1.02, // Adjust the number of slides as needed
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
  // $(".reel").on("click", function () {
  //   var videoUrl = $(this).attr("data-video");
  //   if (videoUrl) {
  //     var videoHtml =
  //       '<video controls autoplay><source src="' +
  //       videoUrl +
  //       '" type="video/mp4"></video>';

  //     // Stop and reset other videos
  //     $("video").each(function () {
  //       if (!$(this).parent().is($(this))) {
  //         this.pause(); // Pauses the video
  //         this.currentTime = 0; // Resets the video to the start
  //         this.muted = true; // Optionally mute the video
  //       }
  //     });

  //     // Load and play the clicked video
  //     $(this).html(videoHtml);
  //   }
  // });

  // var videoUrl = $(this).attr("data-video");
  // if (videoUrl) {
  //   var videoHtml =
  //     '<video controls autoplay><source src="' +
  //     videoUrl +
  //     '" type="video/mp4"></video>';

  //   // Stop and reset other videos
  //   $("video").each(function () {
  //     this.pause(); // Pauses the video
  //     this.currentTime = 0; // Resets the video to the start
  //     this.muted = true; // Optionally mute the video
  //   });

  //   // Remove any existing video inside this reel (if any)
  //   $(this).find(".video-container").remove();
  //   // Remove placeholder image
  //   $(this).find("img").remove();

  //   // Insert the new video at the beginning of the reel
  //   $(this).prepend('<div class="video-container">' + videoHtml + "</div>");
  // }

  if ($(".shorts_player").length) {
    const shortViewer = $(".shorts_player");

    var loadingNewReel = false;

    // Attach the scroll event listener to the entire window
    $('.reel_wrapper').on("scroll", function () {

      console.log(loadingNewReel)
      // Only proceed if .shorts_player is currently visible
      if (shortViewer.hasClass("shorts_ready")) {
        var scrollBottom = $(window).scrollTop() + $(window).height();
        var reelWrapperBottom = $('.reel_wrapper').offset().top + $('.reel_wrapper').height();

        if (!loadingNewReel && scrollBottom > reelWrapperBottom - 300) { // 300 is a threshold

          loadingNewReel = true;

          var currentVideoIds = [];
          $(".video_player").each(function () {
            var videoId = $(this).attr("data-id");
            if (videoId) {
              currentVideoIds.push(videoId);
            }
          });

          console.log(currentVideoIds);

          // AJAX call to fetch new reel
          $.ajax({
            url: "/wp-admin/admin-ajax.php",
            type: "POST",
            data: {
              action: "load_new_reel",
              exclude_ids: currentVideoIds,
            },
            success: function (response) {
              var data = JSON.parse(response);

              if (data.video_url) {
                // Create a new reel_player element
                var newReelPlayer = $("<div>", {
                  class: "reel_player"
                });

                // Create the description toggle button
                var descriptionToggle = $('<div>', {
                  class: 'description_toggle'
                }).html('<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57847 12.422 0 8 0ZM8 14.8837C4.20431 14.8837 1.11628 11.7957 1.11628 8C1.11628 4.20428 4.20431 1.11628 8 1.11628C11.7957 1.11628 14.8837 4.20428 14.8837 8C14.8837 11.7957 11.7957 14.8837 8 14.8837Z" fill="white"></path><path d="M8.0003 6.6687C7.52642 6.6687 7.18945 6.86883 7.18945 7.16367V11.1757C7.18945 11.4285 7.52642 11.6812 8.0003 11.6812C8.45311 11.6812 8.82164 11.4285 8.82164 11.1757V7.16361C8.82164 6.86879 8.45311 6.6687 8.0003 6.6687Z" fill="white"></path><path d="M8.00019 4.19409C7.51578 4.19409 7.13672 4.54159 7.13672 4.94175C7.13672 5.34194 7.51581 5.69997 8.00019 5.69997C8.47406 5.69997 8.85319 5.34194 8.85319 4.94175C8.85319 4.54159 8.47403 4.19409 8.00019 4.19409Z" fill="white"></path></svg>');

                // Create the description content
                var descriptionContent = $('<div>', {
                  class: 'description',
                  style: 'display: none;'
                }).append($('<h3>').text('Description'), $('<p>').text(data.description));

                // Create the video element with its source
                var newVideo = $("<video>", {
                  controls: true,
                  class: "video_player active"
                });
                var newSource = $("<source>", {
                  src: data.video_url,
                  type: "video/mp4"
                });
                newVideo.append(newSource);
                newVideo.append("Your browser does not support the video tag."); // Fallback content

                // Append the description toggle, description content, and video to the reel_player
                newReelPlayer.append(descriptionToggle, descriptionContent, newVideo);

                // Optional: Set the data-id attribute to the new video_player
                newVideo.attr("data-id", data.reel_id);

                // Append the new reel_player to the reel_wrapper
                $(".reel_wrapper").append(newReelPlayer);

                // Optional: Set the data-id attribute to the new video_player
                newVideo.attr("data-id", data.reel_id);
              } else {
                loadingNewReel = false;
                console.log(data.message); // Handle the case where no more reels are available
              }

              loadingNewReel = false;
            },
            error: function (xhr, status, error) {
              console.error("AJAX Error:", status, error);
              loadingNewReel = false;
            },
          });
        }
      }
    });

    $(".reel").on("click", function () {
      // Retrieve the video URL from the clicked .reel's data attribute
      var videoURL = $(this).data("videourl");
      var videoId = $(this).data("id"); // Assuming each .reel has a unique data-id
      var desc = $(this).data("description"); // Assuming each .reel has a unique data-id
      console.log(desc);

      // Construct the video in shorts_player
      var videoPlayer = shortViewer.find(".video_player.active");
      videoPlayer.find("source").attr("src", videoURL);
      videoPlayer[0].load(); // If needed, to reload the video element
      // Correctly target the description paragraph related to the active video player
      var vidDesc = shortViewer.find(".description p");
      vidDesc.text(desc);

      shortViewer.addClass("shorts_ready");

      // Check if the element is not visible and then fade it in
      if (!shortViewer.is(":visible")) {
        shortViewer.fadeIn();
        // Play the video after it's visible
        // videoPlayer[0].play();
      }

      // Update the share links
      updateShareLinks(videoId);

      // Append parameters to URL
      var newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      var queryParameters = new URLSearchParams(window.location.search);
      queryParameters.set("videoId", videoId);
      newUrl += "?" + queryParameters.toString();

      history.pushState({
        path: newUrl
      }, "", newUrl); // Update the URL without reloading

      //update 2nd & 3rd item
      $.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "load_initial_reel",
          exclude_id: videoId,
        },
        success: function (response) {
          var data = JSON.parse(response);
          if (data[0].video_url) {
            var overflow1 = $("#offscreen-1");
            var overflow2 = $("#offscreen-2");

            // Update the video player with new video
            overflow1
              .find(".video_player source")
              .attr("src", data[0].video_url)
              .attr("data-id", data[0].reel_id);
            overflow1.find(".video_player")[0].load();
            overflow1.find("img").remove();

            // Update the first description text
            overflow1
              .find(".description p")
              .text(data[0].description); // Set the description text for the first item


            overflow2
              .find(".video_player source")
              .attr("src", data[1].video_url)
              .attr("data-id", data[1].reel_id);
            overflow2.find(".video_player")[0].load();
            overflow2.find("img").remove();

            // Update the second description text
            overflow2
              .find(".description p")
              .text(data[1].description); // Set the description text for the second item

          }
        },
      });
    });

    // Function to load video from URL parameters
    function loadVideoFromParams() {
      var queryParameters = new URLSearchParams(window.location.search);
      var videoId = queryParameters.get("videoId");

      if (videoId) {
        var reelElement = $('.reel[data-id="' + videoId + '"]');
        if (reelElement.length) {
          reelElement.click(); // Trigger click to load and play the video
        }
      }
    }

    loadVideoFromParams();

    // Handle click event on .exit elements
    $(".exit").on("click", function () {
      // Check if the element is visible
      if (shortViewer.is(":visible")) {
        // Fade out the shorts_player
        shortViewer.fadeOut();
        // Optional: Pause the video when closing the player
        shortViewer.find(".video_player")[0].pause();
      }

      shortViewer.removeClass("shorts_ready");
    });

    function updateShareLinks(videoId) {
      // Get the base URL (up to the pathname)
      var baseUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;

      //Messenger base
      var messengerUrlBase = "fb-messenger://share/?link=";

      // Construct the new URL with the video ID
      var newUrl = baseUrl + "?videoId=" + videoId;

      // Update the mailto link
      $('a[href^="mailto:"]').attr("href", function () {
        return (
          "mailto:?subject=Check this out&body=" + encodeURIComponent(newUrl)
        );
      });

      // Update the Facebook share link
      $('a[href^="https://www.facebook.com"]').attr(
        "href",
        "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(newUrl)
      );

      // Update the LinkedIn share link
      $('a[href^="https://www.linkedin.com"]').attr(
        "href",
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
        encodeURIComponent(newUrl)
      );

      var messengerUrl = messengerUrlBase + encodeURIComponent(newUrl);
      $("#messengerShare").attr("href", messengerUrl);
    }
  }

  $(".reels").mousemove(function (e) {
    var sliderWidth = $(this).width();
    var x = e.pageX - $(this).offset().left;

    // Sensitivity area (e.g., 50 pixels from the edges)
    var sensitivity = 50;

    // Check if cursor is near the left edge
    if (x < sensitivity) {
      $(this).slick("slickPrev");
    }
    // Check if cursor is near the right edge
    else if (x > sliderWidth - sensitivity) {
      $(this).slick("slickNext");
    }
  });
});

/**
 * Load in
 */
$(document).ready(function () {
  // Function to check if an element is in the viewport
  function isElementInView(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  // Function to add 'scrolled-into-view' class to elements in view
  function checkVisibility() {
    $("section").each(function () {
      if (isElementInView(this)) {
        $(this).addClass("scrolled-into-view");
      }
    });
  }

  // Check visibility on load
  checkVisibility();

  // Check visibility on scroll
  $(window).on("scroll", function () {
    checkVisibility();
  });
});

jQuery(document).ready(function ($) {
  $(".solution_post_loop_item img").on("click", function () {
    // Get the href attribute of the sibling anchor tag
    var linkUrl = $(this)
      .closest(".solution_post_loop_item")
      .find("a")
      .attr("href");

    // Redirect to the link
    window.location.href = linkUrl;
  });
});

$(document).ready(function () {
  if ($(".rotating-text-wrapper").length) {
    // Retrieve the statements data from the data attribute
    var statements = $(".rotating-text-wrapper").data("statements");
    var currentIndex = 0; // Start with the first statement

    // Function to rotate statements
    function rotateStatements() {
      currentIndex++; // Increment the index
      if (currentIndex >= statements.length) {
        currentIndex = 0; // Reset index if it's at the end of the array
      }

      // Animate the statement change
      $(".rotating-text-wrapper h3 span").fadeOut(function () {
        $(this).text(statements[currentIndex]["statement"]).fadeIn();
      });
    }

    // Set the interval for changing statements
    setInterval(rotateStatements, 3200); // Change every 4 seconds
  }
});

$(document).ready(function () {
  $(".show_all_podcasts").on("click", function () {
    // Remove the 'show_all_podcasts' class from the clicked element
    $(this).hide();

    // Fade in the '.rest-of-podcasts' elements
    $(".rest-of-podcasts").fadeIn();
  });
});

$(document).ready(function () {

  if ($('.campaign-0').length) {

    // Fetch the event end time from an element with class 'timer'
    const eventEndTimeUnix = parseInt($('.timer').data('date'), 10);
    // Check if the fetched timestamp is valid
    if (isNaN(eventEndTimeUnix)) {
      console.error("Event end time is invalid.");
      return;
    }
    const eventEndTimeMillis = eventEndTimeUnix * 1000;
    console.log(eventEndTimeMillis);

    // Check if the countdown display elements exist
    if ($('.campaign-0 .day').length) {
      setupCountdown(".campaign-0", Date.now(), eventEndTimeMillis);
    }



  }
});

function setupCountdown(campaignSelector, startTimeMillis, endTimeMillis) {
  console.log(startTimeMillis + ' ' + endTimeMillis);
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  function calculateRemaining() {
    const now = new Date().getTime();
    return now >= startTimeMillis && now < endTimeMillis ? endTimeMillis - now : 0;
  }

  let didRefresh = false;
  let previousGap = calculateRemaining();

  function countdown() {
    const gap = calculateRemaining();
    const shouldRefresh = (previousGap > day && gap <= day) || (previousGap > 0 && gap === 0);

    previousGap = gap;

    const textDay = Math.floor(gap / day);
    const textHour = Math.floor((gap % day) / hour);
    const textMinute = Math.floor((gap % hour) / minute);
    const textSecond = Math.floor((gap % minute) / second);

    $(campaignSelector + ' .day').text(textDay);
    $(campaignSelector + ' .hour').text(textHour);
    $(campaignSelector + ' .minute').text(textMinute);
    $(campaignSelector + ' .second').text(textSecond);

    if (shouldRefresh && !didRefresh) {
      didRefresh = true;
      setTimeout(function () {
        window.location.reload();
      }, 30000 + Math.random() * 90000);
    }
  }

  countdown(); // Initialize the countdown display
  return setInterval(countdown, 1000); // Update the countdown every second
}

jQuery(document).ready(function ($) {
  $(document).on('mouseenter', '.facilitator-loop-item', function () {
    var videoUrl = $(this).attr('data-hover');

    if (videoUrl) {
      var img = $(this).find('img');
      img.hide();

      // Create a video element and set it to muted
      var video = $('<video muted loop autoplay style="width:100%;"></video>');
      video.attr('src', videoUrl);
      video.prop('muted', true);

      // Create an unmute button
      var unmuteBtn = $('<button class="unmute-btn">Unmute</button>');
      unmuteBtn.css({
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 10,
        padding: '12px'
      });

      // Insert the video and the button
      img.after(video);
      video.after(unmuteBtn);

      // Toggle mute state on button click
      unmuteBtn.click(function (e) {
        e.preventDefault();
        if (video.prop('muted')) {
          video.prop('muted', false);
          unmuteBtn.text('Mute');
        } else {
          video.prop('muted', true);
          unmuteBtn.text('Unmute');
        }
      });

      $(this).on('mouseleave', function () {
        video.remove();
        img.show();
        unmuteBtn.remove();
      });

      // Play the video explicitly
      video[0].play().catch(function (error) {
        console.error("Video play failed:", error);
      });
    }
  });
});

jQuery(document).ready(function ($) {
  $(document).on('mouseenter', '.facilitators-head img', function () {
    var videoUrl = $('.facilitators-head').attr('data-hover');

    if (videoUrl) {
      var img = $('.facilitators-head').find('img');
      img.hide();

      // Create a video element and set it to muted
      var video = $('<video muted loop autoplay style="width:100%;"></video>');
      video.attr('src', videoUrl);
      video.prop('muted', true);

      // Create an unmute button
      var unmuteBtn = $('<button class="unmute-btn">Unmute</button>');
      unmuteBtn.css({
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 10,
        padding: '12px'
      });

      // Insert the video and the button
      img.after(video);
      video.after(unmuteBtn);

      // Toggle mute state on button click
      unmuteBtn.click(function (e) {
        e.preventDefault();
        if (video.prop('muted')) {
          video.prop('muted', false);
          unmuteBtn.text('Mute');
        } else {
          video.prop('muted', true);
          unmuteBtn.text('Unmute');
        }
      });


      // Play the video explicitly
      video[0].play().catch(function (error) {
        console.error("Video play failed:", error);
      });
    }
  });
});

jQuery(document).ready(function ($) {
  var isVideoLoaded = false; // Flag to check if video is already loaded

  $(window).scroll(function () {
    $('.quote').each(function () {
      var $quote = $(this);
      var videoUrl = $quote.data('video-url');

      if (videoUrl && isInViewport($quote) && !isVideoLoaded) {
        // Create video element
        var video = $('<video />', {
          src: videoUrl,
          autoplay: true,
          loop: true,
          muted: true,
          style: 'width: 100%; height: auto;'
        });

        // Hide the existing img
        $quote.find('img').hide();

        // Add video as the first child of .quote
        $quote.prepend(video);

        // Set flag to true to avoid loading video multiple times
        isVideoLoaded = true;
      }
    });
  });

  // Function to check if element is in viewport
  function isInViewport(element) {
    var elementTop = element.offset().top;
    var elementBottom = elementTop + element.outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
});

jQuery(document).ready(function () {
  if (window.location.hash) {
    var hash = window.location.hash;
    // Remove the leading "#" character from the hash
    var targetId = hash.substring(1);

    // Scroll to the element with the specified ID
    var $targetElement = jQuery('#' + targetId);
    if ($targetElement.length > 0) {
      jQuery('html, body').animate({
        scrollTop: $targetElement.offset().top
      }, 300);
    }
  }
});

document.addEventListener('wpcf7mailsent', function (event) {
  if (!document.body.classList.contains('page-template-page-templateslaw-campaign-php')) {
    location = '/thank-you-for-contacting-us/';
  }
}, false);

$(document).ready(function () {
  $('#book-call').submit(function (e) {
    e.preventDefault(); // Prevent the normal form submission

    var formData = $(this).serialize() + '&action=book_a_call_fg'; // Serialize the form data and add the action parameter

    // disable the submit button
    $('#book-call button').prop('disabled', true);

    $.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php",
      data: formData,
      success: function (response) {
        console.log('Form submission successful');
        window.location.href = "/hca-thank-you/"; // Redirect on success
      },
      error: function () {
        console.error('Form submission failed');
        // enable the submit button
        $('#book-call button').prop('disabled', false);
      }
    });
  });
});

$(document).ready(function () {
  // Check if the element with the ID 'workshop_url' exists
  var workshopUrlElement = $('#workshop_url');
  var $checking = $('#workshop_url_below');

  if ($checking.length) {
    console.log('Workshop URL added to Form' + workshopUrlElement);
    console.log(window.location.href);
    // Update the value of the element to be the current page URL
    workshopUrlElement.val(window.location.href);
  }
});



document.addEventListener("DOMContentLoaded", function () {
  const video = document.querySelector('.circle-video video');
  const playButton = document.querySelector('#play-button');
  let hasClickedPlay = false;

  if (playButton) {
    // Toggle play/pause when the play button is clicked
    playButton.addEventListener('click', function () {
      if (video.paused) {
        video.play();
        
        playButton.classList.add('hide'); // Hide play button when video is playing
      } else {
        // Video playing
        if (!hasClickedPlay) {
          console.log('clicked')
          hasClickedPlay = true;
          video.muted = false;
          // set video playtime to 0
          video.currentTime = 0;
          playButton.classList.add('hide'); // Show play button when video is paused

          return;
        }
        video.pause();
        playButton.classList.remove('hide'); // Show play button when video is paused
        
      }
    });

    // Hide the play button when the video is clicked to play
    video.addEventListener('play', function () {
      if (hasClickedPlay) {
        playButton.classList.add('hide');
      }
    });

    // Show the play button when the video is clicked to pause
    video.addEventListener('pause', function () {
      playButton.classList.remove('hide');
    });

    // Allow clicking on the video itself to toggle play/pause
    video.addEventListener('click', function () {
      if (video.paused) {
        video.play();
      } else {
        if (!hasClickedPlay) {
          console.log('clicked')
          hasClickedPlay = true;
          video.muted = false;
          // set video playtime to 0
          video.currentTime = 0;
          playButton.classList.add('hide'); // Show play button when video is paused

          return;
        }
        video.pause();

      }
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const floatingBallSection = document.querySelector('.floating-balls');

  if (!floatingBallSection) {
    return;
  }

  const floatingVideos = floatingBallSection.querySelectorAll('video');


  // Create observer to add a class when the section is in view
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        floatingBallSection.classList.add('in-view');

        // Play all videos when the section is in view
        floatingVideos.forEach(video => {
          video.play();
        });

        observer.unobserve(entry.target); // Unobserve after it has entered the viewport
      }
    });
  }, {
    threshold: 0.5 // Set the threshold to 0.5
  });

  observer.observe(floatingBallSection); // Observe the floatingBallSection element
});

document.addEventListener('DOMContentLoaded', function () {
  // Select all release-date divs and their span elements
  var releaseDateDivs = document.querySelectorAll('.release-date');

  if (!releaseDateDivs.length) {
    return;
  }

  releaseDateDivs.forEach(function (releaseDateDiv) {
    var spans = releaseDateDiv.getElementsByTagName('span');

    // Read the initial values from the DOM
    var days = parseInt(spans[0].childNodes[0].nodeValue);
    var hours = parseInt(spans[1].childNodes[0].nodeValue);
    var minutes = parseInt(spans[2].childNodes[0].nodeValue);
    var seconds = parseInt(spans[3].childNodes[0].nodeValue);

    // Calculate the total seconds from the initial values
    var totalSeconds = (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;

    // Function to update the countdown every second
    function updateCountdown() {
      if (totalSeconds <= 0) {
        clearInterval(countdownInterval);
        // Optionally, you can add code here to execute when the countdown reaches zero
        return;
      }

      totalSeconds--;

      var days = Math.floor(totalSeconds / (3600 * 24));
      var hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      var minutes = Math.floor((totalSeconds % 3600) / 60);
      var seconds = totalSeconds % 60;

      // Update the text content of each span
      spans[0].childNodes[0].nodeValue = days;
      spans[1].childNodes[0].nodeValue = hours;
      spans[2].childNodes[0].nodeValue = minutes;
      spans[3].childNodes[0].nodeValue = seconds;
    }

    // Start the countdown timer
    var countdownInterval = setInterval(updateCountdown, 1000);
  });
});

class VideoChapterPlugin {
  constructor(options) {
    // Default options
    const defaultOptions = {
      videoSelector: '#main-video',
      playButtonSelector: '#play-main-video',
      chapterContainerSelector: '#video-chapters-wrap',
      observerOptions: {
        threshold: 0.5
      },
    };

    // Merge user options with default options
    this.options = {
      ...defaultOptions,
      ...options
    };

    // Initialize properties
    this.player = document.querySelector(this.options.videoSelector);
    this.playButton = document.querySelector(this.options.playButtonSelector);
    this.chapterContainer = document.querySelector(this.options.chapterContainerSelector);

    if (!this.player) {
      console.error('Video element not found');
      return;
    }

    this.init();
  }

  init() {
    // Intersection Observer to detect when the video is in view
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log('Video is in view, initializing...');

          // Ensure the video starts loading
          this.player.load();
          this.observer.unobserve(this.player); // Stop observing after it's loaded
          this.setupVideoEventListeners();
        }
      });
    }, this.options.observerOptions);

    this.observer.observe(this.player);
  }

  setupVideoEventListeners() {
    console.log('Checking main video element:', this.player);
    this.player.addEventListener('loadedmetadata', () => {
      console.log('Metadata loaded');
      const tracks = this.player.textTracks;
      if (!tracks) {
        console.error('Tracks are not available.');
        return;
      }

      let chaptersTrack;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].kind === 'chapters') {
          chaptersTrack = tracks[i];
          break;
        }
      }


      if (chaptersTrack) {
        chaptersTrack.mode = 'hidden';
        // this.loadCues(chaptersTrack);
      }
    });

    // Play button functionality
    if (this.playButton) {
      this.playButton.addEventListener('click', () => {
        this.player.play();
        this.playButton.classList.add('hide');
      });
    }

    // Show or hide play button based on video state
    this.player.addEventListener('pause', () => {
      if (this.playButton) this.playButton.classList.remove('hide');
    });
    this.player.addEventListener('play', () => {
      if (this.playButton) this.playButton.classList.add('hide');
    });
  }

  loadCues(chaptersTrack) {
    const cues = chaptersTrack.cues;
    if (cues && cues.length > 0) {
      for (let j = 0; j < cues.length; j++) {
        const cue = cues[j];
        const chapterElement = this.createChapterElement(cue, j);
        if (this.chapterContainer) {
          this.chapterContainer.appendChild(chapterElement);
        }
      }
    } else {
      // Retry loading cues if they aren't available yet
      setTimeout(() => this.loadCues(chaptersTrack), 100);
    }
  }

  createChapterElement(cue, index) {
    const chapterElement = document.createElement('div');
    chapterElement.classList.add('chapter');
    const chapterNumber = document.createElement('span');
    chapterNumber.classList.add('chapter-number');
    chapterNumber.textContent = index + 1;

    const chapterTitle = document.createElement('span');
    const chapterTitleInner = document.createElement('span');
    chapterTitle.classList.add('chapter-title');
    chapterTitleInner.textContent = cue.text;
    chapterTitle.appendChild(chapterTitleInner);

    const chapterTitleTimestamp = document.createElement('span');
    chapterTitleTimestamp.classList.add('chapter-timestamp');
    chapterTitleTimestamp.textContent = this.formatTime(cue.startTime);

    chapterElement.style.cursor = 'pointer';
    chapterElement.dataset.startTime = cue.startTime;
    chapterElement.addEventListener('click', () => {
      this.player.currentTime = cue.startTime;
      this.player.play();
    });

    chapterElement.appendChild(chapterNumber);
    chapterElement.appendChild(chapterTitle);
    chapterTitle.appendChild(chapterTitleTimestamp);

    return chapterElement;
  }

  formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${secs
        .toString()
        .padStart(2, '0')}`;
    } else {
      return `${minutes}:${secs.toString().padStart(2, '0')}`;
    }
  }
}

  

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('#main-video')) {
    return;
  }
  const videoPlugin = new VideoChapterPlugin({
    videoSelector: '#main-video', // Selector for the video element
    playButtonSelector: '#play-main-video', // Selector for the play button
    chapterContainerSelector: '#video-chapters-wrap', // Selector for the chapter container
  });

  if (!document.querySelector('.audio-box.chapter')) {
    return;
  }

  document.querySelectorAll('.audio-box.chapter').forEach(box => {
    box.addEventListener('click', () => {
      const video = document.getElementById('main-video');
      const startTimeStr = box.getAttribute('data-start-time');
      
      // Convert timestamp format HH:MM:SS:mmm to seconds
      const parts = startTimeStr.split(':');
      let timestamp = 0;
      
      if (parts.length >= 3) {
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10); 
        const seconds = parseInt(parts[2], 10);
        timestamp = (hours * 3600) + (minutes * 60) + seconds;
      }

      if (!isNaN(timestamp) && isFinite(timestamp)) {
        video.currentTime = timestamp;
      }
    });
  });


});


document.addEventListener("DOMContentLoaded", function () {
  const questionsContainer = document.querySelector(".accordion");

  if (!$('.accordion-item')) {
    return;
  }


  $('.accordion-item').on('click', function (event) {


    // Set video position to the timestamp of the clicked accordion item if the target is .audio-box
    if ($(event.target).closest('.audio-box').length) {
      const timestampStr = $(event.target).closest('.audio-box').data('timestamp');
      const video = document.getElementById('secondary-video');


      if (video && timestampStr) {
        const parts = timestampStr.split(':');
        let timestamp = 0;

        if (parts.length === 2) {
          const minutes = parseInt(parts[0], 10);
          const seconds = parseInt(parts[1], 10);
          timestamp = (minutes * 60) + seconds;
        } else if (parts.length === 3) {
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          const seconds = parseInt(parts[2], 10);
          timestamp = (hours * 3600) + (minutes * 60) + seconds;
        }

        if (!isNaN(timestamp) && isFinite(timestamp)) {
          video.currentTime = timestamp;
          video.play();
        }

        if (document.querySelector('.secondary-video .video-wrap .contents')) {
          document.querySelector('.secondary-video .video-wrap .contents').style.height = '0%';
        }
      }

      return;
    }

    // Toggle the current accordion item's content
    $(this).find('.accordion-content').slideToggle();

    // Remove fixed height from container to allow for dynamic height adjustment
    $('.accordion-container').css('height', 'auto');




  });






});



document.addEventListener('DOMContentLoaded', function () {
  // Append "Read more" to .workshops .questions-box .listen-box p
  const listenBoxParagraph = document.querySelector('.workshops .questions-box .listen-box .open-content p');
  console.log(listenBoxParagraph);
  if (listenBoxParagraph) {


    const readMore = document.createElement('span');
    readMore.classList.add('read-more');
    readMore.textContent = ' Read more';
    readMore.style.color = '#FF4E00';
    readMore.style.cursor = 'pointer';
    listenBoxParagraph.appendChild(readMore);

    readMore.addEventListener('click', () => {
      if (listenBoxParagraph.style.maxHeight) {
        listenBoxParagraph.style.maxHeight = null;
        listenBoxParagraph.style.overflow = 'hidden'; // Hide extra content
        document.querySelector('.workshops .questions-box .listen-box .open-content').classList.remove('active');
        readMore.textContent = ' Read more';
      } else {
        listenBoxParagraph.style.maxHeight = `${listenBoxParagraph.scrollHeight}px`;
        listenBoxParagraph.style.overflow = 'visible'; // Show all content
        document.querySelector('.workshops .questions-box .listen-box .open-content').classList.add('active');
        readMore.textContent = ' Read less';
      }
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const videoWrap = document.querySelector('.secondary-video .video-wrap .contents');

  if (!videoWrap) {
    return;
  }

  let isDragging = false;
  let startY;
  let startHeight;
  if (window.innerWidth <= 768) { // Mobile only
    videoWrap.addEventListener('touchstart', function (e) {
      if (e.target.closest('.header')) {
        startY = e.touches[0].clientY;
        startHeight = parseInt(document.defaultView.getComputedStyle(videoWrap).height, 10);
        videoWrap.style.transition = 'none';
        e.preventDefault(); // Prevent default scroll
      }
    });

    document.addEventListener('touchmove', function (e) {
      if (startY !== undefined) {
        isDragging = true;
        const newHeight = startHeight - (e.touches[0].clientY - startY);
        videoWrap.style.height = `${newHeight}px`;
        e.preventDefault(); // Prevent default scroll
      }
    });

    document.addEventListener('touchend', function () {
      if (isDragging) {
        isDragging = false;
        startY = undefined;
        videoWrap.style.transition = 'height 0.3s ease-in-out';
      }
    });




  }

  const header = document.querySelector('.secondary-video .video-wrap .contents .header');
  header.addEventListener('click', function () {
    if (videoWrap.style.height !== '100%') {
      videoWrap.style.height = '100%';
      return;
    } else {
      videoWrap.style.height = '0%';
    }
  });

  header.addEventListener('touchend', function (e) {
    if (isDragging) {
      isDragging = false;
      return;
    }
    if (videoWrap.style.height !== '100%') {
      videoWrap.style.height = '100%';
    } else {
      videoWrap.style.height = '0%';
    }
  });
});